
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































































































































































































































































































$masonry-gutter: 2rem;
$masonry-gutter-half: $masonry-gutter / 2;
$masonry-max-width: 116.8rem + $masonry-gutter;

.tv-universe-sport {
  position: relative;
  min-height: 100vh;
  color: $white;
  background: $c-promo-card;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
  }

  &::before {
    background: radial-gradient(
      70.77% 37.46% at 121.79% 106.52%,
      rgba(12, 186, 186, 0.42) 0%,
      rgba(12, 186, 186, 0.14) 49.3%,
      rgba(12, 186, 186, 0) 100%
    );
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(
        143.46% 103.5% at -2.56% 30.21%,
        rgba(12, 186, 186, 0.42) 0%,
        rgba(12, 186, 186, 0.14) 49.3%,
        rgba(12, 186, 186, 0) 100%
      );
  }
}

.tv-universe-sport__inner {
  // prettier-ignore
  @include fluid(padding-top, (s: 150px, l: 250px));

  position: relative;
  z-index: 1;
}

.masonry-grid-sizer {
  width: 50%;
}

.masonry-container {
  margin: 0 auto;
  margin-bottom: 7rem;
  max-width: $masonry-max-width;

  @include mq(l) {
    margin-bottom: 10rem;
  }
}

.masonry-item {
  width: 100%;
  margin-bottom: 5rem;
  padding: 0 $masonry-gutter-half;

  @include mq(l) {
    margin-bottom: $masonry-gutter;

    &:not(.desktop-fullwidth, .only-result) {
      width: 50%;
    }

    // Centers sport rack if only one is being displayed
    // This rule does not apply to "Foot belge" since it is a special case.
    &.only-result:not(.desktop-fullwidth) {
      ::v-deep {
        .sport {
          width: 50%;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}

.sticky-cta-container {
  display: none;
  position: sticky;
  bottom: 5rem;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all $ease-out-cubic 500ms;

  &.stuck {
    opacity: 1;
    transform: translateY(0);
  }

  .sticky-cta {
    // prettier-ignore
    box-shadow:
      0px 0px 4.1px rgba(8, 40, 51, 0.141),
      0px 0px 9.9px rgba(8, 40, 51, 0.202),
      0px 0px 18.7px rgba(8, 40, 51,  0.25),
      0px 0px 33.3px rgba(8, 40, 51, 0.298),
      0px 0px 62.2px rgba(8, 40, 51,  0.359),
      0px 0px 149px rgba(8, 40, 51, 0.5)
    ;
  }
}

.sports-container {
  &.has-sticky-cta {
    .not-highlighted {
      @include mq($until: l) {
        // Hide individual sport racks CTA while maintaining height.
        // We'll use the sticky CTA instead.
        ::v-deep {
          .cta-container {
            display: none;
          }
        }

        // On last soprt rack, we'll also remove height so that the sticky CTA
        // fits nicely.
        &:last-child {
          ::v-deep {
            .cta-container {
              display: none;
            }
          }
        }
      }
    }

    .sticky-cta-container {
      display: block;

      @include mq(l) {
        display: none;
      }
    }
  }
}

.conditions-container {
  position: relative;
  z-index: 1;
  color: $black;
}
