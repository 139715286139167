
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




















































































































































































































.title {
  // prettier-ignore
  @include fluid(margin-bottom, (s: 23px, l: 30px));
}

.sport {
  &.desktop-fullwidth {
    .card-collection {
      @include mq($until: l) {
        /* stylelint-disable-next-line declaration-no-important */
        grid-template-columns: repeat(2, 1fr) !important;
      }

      @include mq(l) {
        grid-template-rows: unset;
      }
    }
  }

  &:not(.desktop-fullwidth) {
    .card-collection {
      /* stylelint-disable-next-line declaration-no-important */
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  @include mq(l) {
    padding: 3rem 6rem 4rem;
    background-color: transparentize($white, 0.9);
    border-radius: 1rem;
    box-shadow: $box-shadow-lvl-1;
    transition: background-color $ease-out-cubic 500ms;

    &:hover {
      background-color: transparentize($white, 0.8);
    }
  }
}

.card-collection {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  margin-bottom: 3rem;
}

.card-collection__entry {
  background: transparent;
  border-width: 0;
  padding: 0;

  &.spans-2-cols {
    grid-column: span 2;
  }

  &.spans-2-rows {
    grid-row: span 2;
  }

  ::v-deep {
    .card {
      height: 100%;

      img {
        object-position: top;
      }
    }
  }
}

.cta-container {
  margin-bottom: 5rem;
  text-align: center;

  @include mq(l) {
    margin-bottom: 0;
  }
}
