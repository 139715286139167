
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































































































































































































$max-width: 1045px;
$filter-gutter: 0.5rem;

.title {
  // prettier-ignore
  @include fluid(margin-bottom, (s: 26px, l: 50px));

  @include mq(l) {
    max-width: 45rem;
  }
}

.sports-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $max-width;
}

.filters-container {
  // prettier-ignore
  @include fluid(margin-bottom, (s: 25px, l: 90px));

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -$filter-gutter;
  margin-left: -$filter-gutter;
}

.filter {
  position: relative;
}

.filter__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  & + .filter__label {
    display: flex;
    align-items: center;
    margin: $filter-gutter;
    padding: 1rem 2rem;
    color: $c-blue-light-pale;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    border: 1px $c-blue-light-pale solid;
    border-radius: 0.5rem;
    cursor: pointer;
    user-select: none;
    transition: all $ease-out-cubic 250ms;
  }

  &:checked + .filter__label {
    background: $c-anthracite-light;
    border-color: transparent;
  }
}

.filter__icon {
  display: none;

  @include mq(m) {
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    margin-left: 1rem;
    object-fit: contain;
  }
}

.text {
  // prettier-ignore
  @include fluid(font-size, (s: 12px, l: 18px));

  margin: 0 0 1rem;
  line-height: 1.2;
}

.svg-arrow {
  width: 1rem;
  height: 1rem;
  fill: none;
  stroke: currentColor;
}

.discover-more {
  text-align: center;
}
